import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, useForm } from 'react-final-form';
import { AccordionSet, Accordion, Col, Row, Datepicker, Select, TextArea, TextField } from '@folio/stripes/components';
import { required } from '@folio/stripes/util';
import { Pluggable, useStripes } from '@folio/stripes/core';

const PatronRequestForm = ({ locations, requesters, onSISelect }) => {
  const { change } = useForm();
  const stripes = useStripes();
  useEffect(() => {
    if (locations?.length === 1) {
      change('pickupLocationSlug', locations[0]?.value);
    }
  }, [locations, change]);

  return (
    <AccordionSet>
      <Accordion label={<FormattedMessage id="ui-ill-ui.information.heading.request" />}>
        <Row>
          <Col xs={3}>
            <Field
              id="edit-request-metadata-requestingUser"
              name="patronIdentifier"
              label={<FormattedMessage id="ui-ill-ui.information.requestingUser" />}
              component={TextField}
              required
              validate={required}
            />
          </Col>
          <Col xs={3}>
            <Field
              id="edit-request-metadata-neededBy"
              name="neededBy"
              dateFormat="YYYY-MM-DD"
              backendDateStandard="YYYY-MM-DD"
              label={<FormattedMessage id="ui-ill-ui.information.dateNeeded" />}
              component={Datepicker}
            />
          </Col>
          <Col xs={3}>
            <Field
              id="edit-request-metadata-pickupLocation"
              name="pickupLocationSlug"
              label={<FormattedMessage id="ui-ill-ui.information.pickupLocation" />}
              placeholder=" "
              component={Select}
              dataOptions={locations}
              required
              validate={required}
            />
          </Col>
          <Col xs={3}>
            <Field
              id="edit-request-metadata-volume"
              name="volume"
              label={<FormattedMessage id="ui-ill-ui.information.volume" />}
              component={TextField}
            />
          </Col>
        </Row>
        {requesters.length > 1 && (
          <Row>
            <Col xs={3}>
              <Field
                id="edit-request-metadata-requestingInstitution"
                name="requestingInstitutionSymbol"
                label={<FormattedMessage id="ui-ill-ui.information.requestingInstitution" />}
                placeholder=" "
                component={Select}
                dataOptions={requesters}
                required
                validate={required}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={9}>
            <Field
              id="edit-request-metadata-patronNote"
              name="patronNote"
              label={<FormattedMessage id="ui-ill-ui.information.notes" />}
              component={TextArea}
              rows={5}
              maxLength={255}
            />
          </Col>
        </Row>
      </Accordion>
      <Accordion
        label={<FormattedMessage id="ui-ill-ui.information.heading.requestedTitle" />}
        displayWhenOpen={<Pluggable
          type={`ill-siquery-${stripes.config?.ill?.sharedIndex?.type}`}
          endpoint={stripes.config?.ill?.sharedIndex?.query}
          searchButtonStyle="primary marginBottom0"
          searchLabel="Populate from shared index"
          selectInstance={onSISelect}
        />}
      >
        <Row>
          <Col xs={4}>
            <Field
              id="edit-patron-request-systemInstanceIdentifier"
              name="systemInstanceIdentifier"
              label={<FormattedMessage id="ui-ill-ui.information.systemInstanceIdentifier" />}
              component={TextField}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <Field
              id="edit-patron-request-title"
              name="title"
              label={<FormattedMessage id="ui-ill-ui.information.title" />}
              component={TextField}
              required
              validate={required}
            />
          </Col>
          <Col xs={4}>
            <Field
              id="edit-patron-request-author"
              name="author"
              label={<FormattedMessage id="ui-ill-ui.information.author" />}
              component={TextField}
              required
              validate={required}
            />
          </Col>
          <Col xs={4}>
            <Field
              id="edit-patron-request-publicationDate"
              name="publicationDate"
              label={<FormattedMessage id="ui-ill-ui.information.date" />}
              component={TextField}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <Field
              id="edit-patron-request-publisher"
              name="publisher"
              label={<FormattedMessage id="ui-ill-ui.information.publisher" />}
              component={TextField}
            />
          </Col>
          <Col xs={4}>
            <Field
              id="edit-patron-request-edition"
              name="edition"
              label={<FormattedMessage id="ui-ill-ui.information.edition" />}
              component={TextField}
            />
          </Col>
          <Col xs={4}>
            <Field
              id="edit-patron-request-placeOfPublication"
              name="placeOfPublication"
              label={<FormattedMessage id="ui-ill-ui.information.placeOfPublication" />}
              component={TextField}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <Field
              id="edit-patron-request-isbn"
              name="isbn"
              label={<FormattedMessage id="ui-ill-ui.information.isbn" />}
              component={TextField}
            />
          </Col>
          <Col xs={4}>
            <Field
              id="edit-patron-request-issn"
              name="issn"
              label={<FormattedMessage id="ui-ill-ui.information.issn" />}
              component={TextField}
            />
          </Col>
          <Col xs={4}>
            <Field
              id="edit-patron-request-oclcNumber"
              name="oclcNumber"
              label={<FormattedMessage id="ui-ill-ui.information.oclcNumber" />}
              component={TextField}
            />
          </Col>
        </Row>
      </Accordion>
    </AccordionSet>
  );
};

export default PatronRequestForm;
