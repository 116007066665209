import React from 'react';
import ActionReasonModal from '../ActionReasonModal';
import { REQUEST_ACTIONS } from '../../../constants/requestActions';

export const SupplierCannotSupply = props => <ActionReasonModal action={REQUEST_ACTIONS.supplierCannotSupply} reasonVocab="cannotSupplyReasons" {...props} />;
export const RequesterCancel = props => <ActionReasonModal action={REQUEST_ACTIONS.requesterCancel} reasonVocab="cancellationReasons" {...props} />;
export const CancelLocal = props => <ActionReasonModal action={REQUEST_ACTIONS.cancelLocal} reasonVocab="cancellationReasons" {...props} />;

export const Cancel = () => null;
export { default as FillMultiVolumeRequest } from './FillMultiVolumeRequest';
export { default as ManualClose } from './ManualClose';
export { default as RespondYes } from './RespondYes';
export { default as SupplierConditionalSupply } from './SupplierConditionalSupply';
export { default as SupplierAddCondition } from './SupplierAddCondition';
export { default as SupplierRespondToCancel } from './SupplierRespondToCancel';
