export default {
  tokens: [
    {
      token: 'locations',
      previewValue: 'location 1, location 2'
    },
    {
      token: 'numRequests',
      previewValue: '35'.toString()
    },
    {
      token: 'illURL',
      previewValue: 'https://folio.url.for.this.install'
    }
  ]
};
