/* eslint-disable import/prefer-default-export */
export const REQUEST_STATUS = {
  requesterAwaitingReturnShipping: 'REQ_AWAITING_RETURN_SHIPPING',
  requesterBorrowingLibraryReceived: 'REQ_BORROWING_LIBRARY_RECEIVED',
  requesterCheckedIn: 'REQ_CHECKED_IN',
  requesterConditionalAnswerReceived: 'REQ_CONDITIONAL_ANSWER_RECEIVED',
  requesterExpectsTosupply: 'REQ_EXPECTS_TO_SUPPLY',
  requesterIdle: 'REQ_IDLE',
  requesterInvalidPatron: 'REQ_INVALID_PATRON',
  requesterLocalReview: 'REQ_LOCAL_REVIEW',
  requesterRequestSentToSupplier: 'REQ_REQUEST_SENT_TO_SUPPLIER',
  requesterShipped: 'REQ_SHIPPED',
  responderAwaitLMSCheckout: 'RES_AWAIT_LMS_CHECKOUT',
  responderAwaitPicking: 'RES_AWAIT_PICKING',
  responderAwaitProxyBorrower: 'RES_AWAIT_PROXY_BORROWER',
  responderAwaitShip: 'RES_AWAIT_SHIP',
  responderCancelRequestReceived: 'RES_CANCEL_REQUEST_RECEIVED',
  responderIdle: 'RES_IDLE',
  responderNewAwaitPullslip: 'RES_NEW_AWAIT_PULL_SLIP',
  responderPendingConditionalAnswer: 'RES_PENDING_CONDITIONAL_ANSWER',
  responderSequestered: 'RES_SEQUESTERED'
};
