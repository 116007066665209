import React from 'react';
import { Button } from '@folio/stripes/components';
import { FormattedMessage } from 'react-intl';
import { DirectLink } from '@k-int/stripes-ill';
import { ShowModalButton } from '../../ModalButtons';
import { REQUEST_ACTIONS } from '../../../constants/requestActions';

export { default as Generic } from './Generic';

// Named corresponding to actions
export const RequesterManualCheckIn = ({ performAction }) => (
  <Button
    onClick={() => performAction(REQUEST_ACTIONS.requesterManualCheckIn, false, {
      success: 'ui-ill-ui.actions.manualCheckIn.success',
      error: 'ui-ill-ui.actions.manualCheckIn.error'
    })}
    buttonStyle="primary mega"
    fullWidth
  >
    <FormattedMessage id="ui-ill-ui.actions.manualCheckIn" />
  </Button>
);

export const SupplierRespondToCancel = () => (
  <ShowModalButton
    modal="RespondToCancel"
    buttonStyle="primary mega"
    fullWidth
  >
    <FormattedMessage id="stripes-ill.actions.supplierRespondToCancel" />
  </ShowModalButton>
);

export const SupplierMarkConditionsAgreed = ({ performAction }) => (
  <Button
    onClick={() => performAction(REQUEST_ACTIONS.supplierMarkConditionsAgreed, false, {
      success: 'ui-ill-ui.actions.supplierMarkConditionsAgreed.success',
      error: 'ui-ill-ui.actions.supplierMarkConditionsAgreed.error'
    })}
    buttonStyle="primary mega"
    fullWidth
  >
    <FormattedMessage id="ui-ill-ui.actions.supplierMarkConditionsAgreed" />
  </Button>
);

export const FillLocally = ({ performAction }) => (
  <Button
    onClick={() => performAction(REQUEST_ACTIONS.fillLocally, false, {
      success: 'stripes-ill.actions.fillLocally.success',
      error: 'stripes-ill.actions.fillLocally.error'
    })}
    buttonStyle="primary mega"
    fullWidth
  >
    <FormattedMessage id="stripes-ill.actions.fillLocally" />
  </Button>
);

export const RequesterAgreeConditions = ({ performAction }) => (
  <Button
    onClick={() => performAction(REQUEST_ACTIONS.requesterAgreeConditions, false, {
      success: 'ui-ill-ui.actions.requesterAgreeConditions.success',
      error: 'ui-ill-ui.actions.requesterAgreeConditions.error'
    })}
    buttonStyle="primary mega"
    fullWidth
  >
    <FormattedMessage id="ui-ill-ui.actions.requesterAgreeConditions" />
  </Button>
);

export { default as SupplierCheckInToIll } from './SupplierCheckInToIll';
export { default as SupplierFillDigitalLoan } from './SupplierFillDigitalLoan';
export { default as PatronReturnedItem } from './PatronReturnedItem';


// Client only, component names do not correspond to actions
export const PrintPullSlip = () => (
  <DirectLink component={Button} buttonStyle="primary mega" fullWidth to="pullslip">
    <FormattedMessage id="ui-ill-ui.printPullslip" />
  </DirectLink>
);
