export const formatConditionNote = (condition) => {
  const { note } = condition;

  if ((note != null) && note.startsWith('#IllAddLoanCondition#')) {
    return note.replace(/^#IllAddLoanCondition# ?/, '');
  } else {
    return note;
  }
};

export const formatConditionCode = (condition, formatMessage) => {
  return formatMessage({
    id: `ui-ill-ui.settings.customiseListSelect.loanConditions.${condition.code}`,
    defaultMessage: condition.code,
  });
};
