import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { ControlledVocab } from '@folio/stripes/smart-components';
import { withStripes } from '@folio/stripes/core';
import { TAGS_ENDPOINT } from '../../constants/endpoints';

class Tags extends React.Component {
  static propTypes = {
    stripes: PropTypes.shape({
      connect: PropTypes.func.isRequired,
    }).isRequired,
    intl: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.connectedControlledVocab = props.stripes.connect(ControlledVocab);
  }

  render() {
    const {
      stripes,
      intl,
    } = this.props;

    return (
      <this.connectedControlledVocab
        baseUrl={TAGS_ENDPOINT}
        canCreate={false}
        clientGeneratePk=""
        columnMapping={{
          value: intl.formatMessage({ id: 'ui-ill-ui.settings.headings.value' }),
          normValue: intl.formatMessage({ id: 'ui-ill-ui.settings.headings.normValue' }),
        }}
        hiddenFields={['lastUpdated', 'numberOfObjects']}
        id="tags"
        label={intl.formatMessage({ id: 'ui-ill-ui.settings.objectName.tags' })}
        labelSingular={intl.formatMessage({ id: 'ui-ill-ui.settings.objectName.tag' })}
        limitParam="perPage"
        objectLabel="Entries"
        sortby="value"
        stripes={stripes}
        visibleFields={['value', 'normValue']}
      />
    );
  }
}

export default injectIntl(withStripes(Tags));
