import initialToUpper from '../../util/initialToUpper';
import { REQUEST_ACTIONS } from '../../constants/requestActions';
import { REQUEST_STATUS } from '../../constants/requestStatus';
import { REQUEST_STATE_MODEL } from '../../constants/requestStateModel';

/*
 * This object describes what to render for a request given its state (directories
 * relative to src/components/Flow).
 *
 * SOME_STATE: {
 *   flowComponents: <array of names of components exported from the FlowViewComponents directory>
 *   primaryAction: <name of component exported from the primaryActions directory>
 *   moreActions: <array of names of components exported from the moreActions directory>
 *   secondHeadline: <array of names of components exported from the secondHeadlines directory>
 * }
 *
 * moreActions will appear after any validActions provided on the request record. Custom
 * components will be used for validActions if available (eg. named after the action).
 * If no primaryAction is specified the first entry in validActions will be used, specify
 * a null primaryAction for states to suppress this.
 *
 * validActions without a component named for them will be rendered with the component
 * "Generic" which is roughly the ActionButton for moreActions and the ScanConfirmAction
 * for a primaryAction with accomodations for missing translations.
 *
 * excludesActions is for the VERY rare cases where an action IS a validAction,
 * but we do not want to explicitly render an action button for it.
 * Example - for RES_AWAIT_SHIP "supplierCheckInToIll" is a validAction,
 * since FillMultiVolumeRequest is valid and uses that action.
 * However the generic "Fill request" option (which uses the same action on the backend)
 * no longer makes sense in this state, so needs to be excluded,
 * and FillMultiVolumeRequest rendered through "moreActions".
 *
 * Translations for generic components:
 *
 * stripes-ill.actions.<action name> - name of action
 * stripes-ill.actions.<action name>.success - message banner when action succeeds
 * stripes-ill.actions.<action name>.error - message banner when action fails
 * ui-ill-ui.actions.<action name>.prompt - prompt for scanning request barcode to exec
 *
 * Where the above are missing, stripes-ill.actions.<action name> will be substituted into
 * ui-ill-ui.actions.generic.* to provide generic success/error/prompt messages.
 *
*/

export const actionsByState = {
  default: {
    flowComponents: ['TitleAndSILink', 'RequestInfo', 'ActionAccordion', 'Volumes', 'LoanConditions'],
    primaryAction: null,
    moreActions: [],
  },
  [REQUEST_STATUS.responderIdle]: {
    primaryAction: null,
  },
  [REQUEST_STATUS.requesterIdle]: {
    primaryAction: null,
  },
  [REQUEST_STATUS.requesterInvalidPatron]: {
    primaryAction: null,
  },
  [REQUEST_STATUS.responderPendingConditionalAnswer]: {
    primaryAction: 'SupplierMarkConditionsAgreed',
  },
  [REQUEST_STATUS.responderNewAwaitPullslip]: {
    primaryAction: 'PrintPullSlip',
  },
  [REQUEST_STATUS.responderAwaitPicking]: {
    primaryAction: 'SupplierCheckInToIll',
    moreActions: ['PrintPullSlip', 'FillMultiVolumeRequest'],
  },
  [REQUEST_STATUS.responderAwaitProxyBorrower]: {
    moreActions: ['FillMultiVolumeRequest'],
  },
  [REQUEST_STATUS.responderAwaitLMSCheckout]: {
    primaryAction: REQUEST_ACTIONS.supplierManualCheckout,
  },
  // TODO we will want to replace primary actions like this sometimes for multivol requests.
  // Perhaps have switch inside the default component itself?
  [REQUEST_STATUS.responderAwaitShip]: {
    primaryAction: REQUEST_ACTIONS.supplierMarkShipped,
    moreActions: ['FillMultiVolumeRequest'],
    excludeActions: [REQUEST_ACTIONS.supplierCheckInToIll]
  },
  [REQUEST_STATUS.responderSequestered]: {
    primaryAction: REQUEST_ACTIONS.supplierFillDigitalLoan,
    excludeActions: [REQUEST_ACTIONS.supplierFillDigitalLoan],
  },
  [REQUEST_STATUS.requesterShipped]: {
    moreActions: ['PrintPullSlip'],
  },
  [REQUEST_STATUS.responderCancelRequestReceived]:{
    primaryAction: 'SupplierRespondToCancel',
  },
  [REQUEST_STATUS.requesterRequestSentToSupplier]: {
    primaryAction: null,
  },
  [REQUEST_STATUS.requesterExpectsTosupply]: {
    primaryAction: null,
  },
  [REQUEST_STATUS.requesterBorrowingLibraryReceived]: {
    primaryAction: 'RequesterManualCheckIn',
  },
  [REQUEST_STATUS.requesterCheckedIn]: {
    primaryAction: 'PatronReturnedItem',
    moreActions: ['PrintPullSlip'],
  },
  [REQUEST_STATUS.requesterConditionalAnswerReceived]: {
    primaryAction: 'RequesterAgreeConditions',
  },
  [REQUEST_STATUS.requesterAwaitingReturnShipping]: {
    moreActions: ['PrintPullSlip'],
  },
  [REQUEST_STATUS.requesterLocalReview]: {
    primaryAction: REQUEST_ACTIONS.fillLocally,
  }
};

/* The idea behind this object is that we can use it to lookup whether or not any given action needs the ability to add a note or not,
   freeing us from having to change multiple places when any changes/additions are made.
*/
export const includesNote = {
  default: false,
  [REQUEST_ACTIONS.supplierPrintPullSlip]: false,
  [REQUEST_ACTIONS.supplierManualCheckout]: false,
  [REQUEST_ACTIONS.supplierMarkShipped]: true,
  [REQUEST_ACTIONS.cancelLocal]: false,
  [REQUEST_ACTIONS.requesterCancel]: true,
  [REQUEST_ACTIONS.requesterReceived]: true,
  [REQUEST_ACTIONS.patronReturnedItem]: false,
  [REQUEST_ACTIONS.shippedReturn]: true,
  [REQUEST_ACTIONS.supplierCheckOutOfIll]: true,
  // Special cases, where this file isn't drawn from at the moment:
  // modals
  RespondYes: true,
  SupplierCannotSupply: true,
  SupplierConditionalSupply: true,
  // others, triggered by other means
  SupplierCheckInToIll: false,
  PrintPullSlip: false,
};

/* Icons for generic more actions */
export const actionIcons = {
  supplierMarkShipped: 'archive',
  supplierPrintPullSlip: 'print',
};

/* Actions from request.validActions to exclude from all states when using the below function */
export const excludeRemote = [
  REQUEST_ACTIONS.message,
  REQUEST_ACTIONS.manualClose,
  REQUEST_ACTIONS.supplierCheckInToIllAndSupplierMarkShipped, // This will replace the action performed by "fill request" depending on config
  REQUEST_ACTIONS.patronReturnedItemAndShippedReturn // This will replace the action performed by "mark returned by patron" depending on config
];

/* Actions from request.validActions that cannot become the primary action */
const excludePrimary = [];

/* UI-only actions to exclude from electronic requests */
const excludeElectronic = ['FillMultiVolumeRequest'];

/* This function returns the contextual actions for a provided request,
 * falling back to the default for unknown states.
 */
export const actionsForRequest = request => {
  /* Since state model types aren't implemented yet and deliveryMethod won't necessarily be set we currently
  need to rely on discrete state model codes to determine if a request is electronic */
  const isElectronic = [
    [REQUEST_STATE_MODEL.responderCDL],
    [REQUEST_STATE_MODEL.requesterDigitalReturnable]
  ].includes(request.stateModel?.shortcode);
  const actions = { ...actionsByState.default, ...actionsByState[request.state?.code] || {} };
  if (Array.isArray(request.validActions)) {
    const remote = request.validActions.filter(
      action => actions.primaryAction !== initialToUpper(action) && !(excludeRemote.includes(action)) && !(actions.excludeActions?.includes(action))
    );
    const client = actions.moreActions.filter(
      action => !(remote.includes(`${action.charAt(0).toLowerCase()}${action.substring(1)}`))
        && !(isElectronic && excludeElectronic.includes(action))
    );
    actions.moreActions = remote.concat(client);
    const maybePrimary = remote.filter(action => !excludePrimary.includes(action));
    if (maybePrimary.length > 0 && actionsByState?.[request.state?.code]?.primaryAction === undefined) actions.primaryAction = maybePrimary[0];
  }
  return actions;
};
