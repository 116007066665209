/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import ReactJson from 'react-json-view';
import PropTypes from 'prop-types';

class DeveloperJson extends React.Component {
  static propTypes = {
    src: PropTypes.object.isRequired
  };

  render() {
    return (
      <ReactJson
        src={this.props.src}
        displayDataTypes={false}
        iconStyle="triangle"
        name={null}
        sortKeys={true}
        theme="brewer"
      />
    );
  }
}

export { DeveloperJson };
