import { useOkapiQuery } from '@k-int/stripes-ill';
import {
  REQUEST_ACTIONS,
  SETTINGS_ENDPOINT
} from '@k-int/ill-ui';

export default () => {
  const settingsQuery = useOkapiQuery(SETTINGS_ENDPOINT, {
    searchParams: { filters: 'section==state_action_config', perPage: '1000' },
    staleTime: 10 * 60 * 1000,
    cacheTime: 8 * 60 * 60 * 1000,
  });
  if (!settingsQuery.isSuccess) return null;
  const settings = settingsQuery.data.reduce((acc, el) => ({ ...acc, [el.key]: el.value === "yes" }), {});
  const supplierMaybeCombined = settings.combine_fill_and_ship ? [REQUEST_ACTIONS.supplierCheckInToIllAndSupplierMarkShipped] : [REQUEST_ACTIONS.supplierCheckInToIll, REQUEST_ACTIONS.supplierMarkShipped];
  const requesterMaybeCombined = settings.combine_returned_by_patron_and_return_ship ? [REQUEST_ACTIONS.patronReturnedItemAndShippedReturn] : [REQUEST_ACTIONS.patronReturnedItem, REQUEST_ACTIONS.shippedReturn];
  return [
    ...supplierMaybeCombined,
    REQUEST_ACTIONS.supplierCheckOutOfIll,
    REQUEST_ACTIONS.requesterReceived,
    ...requesterMaybeCombined,
  ];
};
