import React, { useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { CalloutContext, useOkapiKy } from '@folio/stripes/core';
import { Button, Pane, Paneset } from '@folio/stripes/components';
import { useOkapiQuery, usePerformAction } from '@k-int/stripes-ill';
import {
  PATRON_REQUESTS_MARK_BATCH_AS_PRINTED_ENDPOINT,
  REPORT_GENERATE_PICKLIST_ENDPOINT,
  PATRON_REQUESTS_ENDPOINT,
  PATRON_REQUESTS_WITH_SEPARATOR_ENDPOINT
} from '../constants/endpoints';
import { REQUEST_ACTIONS } from '../constants/requestActions';

const PullSlipRoute = ({ match, history }) => {
  const requestId = match.params?.id;
  const batchId = match.params?.batchId;
  const [pdfUrl, setPdfUrl] = useState();
  const intl = useIntl();
  const okapiKy = useOkapiKy();
  const queryClient = useQueryClient();
  const callout = useContext(CalloutContext);
  const performAction = usePerformAction(requestId);
  const title = intl.formatMessage({ id: requestId ? 'ui-ill-ui.pullSlip' : 'ui-ill-ui.pullSlips' });

  const reqQuery = useOkapiQuery(`${PATRON_REQUESTS_WITH_SEPARATOR_ENDPOINT}${requestId}`, {
    enabled: !!requestId,
  });
  const isReqPrintable = reqQuery?.data?.validActions?.includes('supplierPrintPullSlip');

  const fetchPath = REPORT_GENERATE_PICKLIST_ENDPOINT;
  const fetchParams = requestId ? { requestId } : { batchId };
  const pdfQuery = useQuery({
    queryKey: [fetchPath, fetchParams],
    queryFn: () => okapiKy(fetchPath, { searchParams: fetchParams }).blob(),
  });

  useEffect(() => {
    if (pdfQuery.isSuccess && !pdfUrl) {
      setPdfUrl(URL.createObjectURL(pdfQuery.data));
    }
  }, [pdfQuery.isSuccess, pdfQuery.data, pdfUrl]);

  if (!pdfUrl) return null;

  const markPrinted = () => {
    if (requestId && isReqPrintable) {
      performAction(REQUEST_ACTIONS.supplierPrintPullSlip);
    } else if (batchId) {
      okapiKy(PATRON_REQUESTS_MARK_BATCH_AS_PRINTED_ENDPOINT, { searchParams: { batchId } }).then(() => {
        queryClient.invalidateQueries(PATRON_REQUESTS_ENDPOINT);
        callout.sendCallout({ type: 'success', message: intl.formatMessage({ id: 'ui-ill-ui.pullSlip.mark.success' }) });
      }).catch(async e => {
        const message = intl.formatMessage({ id: 'ui-ill-ui.pullSlip.mark.error' }, { errMsg: e.message });
        callout.sendCallout({ type: 'error', message });
      });
    }
  };

  return (
    <Paneset>
      <Pane
        defaultWidth="100%"
        onClose={history.goBack}
        dismissible
        paneTitle={title}
        lastMenu={
          <Button
            buttonStyle="primary"
            marginBottom0
            disabled={(requestId && !reqQuery.isSuccess) || (reqQuery.isSuccess && !isReqPrintable)}
            onClick={markPrinted}
          >
            <FormattedMessage id="ui-ill-ui.pullSlip.mark" />
          </Button>
        }
      >
        <iframe src={pdfUrl} width="100%" height="100%" title={title} />
      </Pane>
    </Paneset>
  );
};

export default PullSlipRoute;
